import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import i18next from "i18next";
import ListGame from "./ListGame";

const GamePage = observer(() => {
  const [game_type, setGameType] = useState("all");
  return (
    <>
      <HeaderTitle title={i18next.t("game_title")} isBack={false} />
      <div className="main-content">
        <div className="page-content">
          <div className="game-container">
            {/* <div className="left">
              <div
                className={classNames("key-item", {
                  active: game_type === "all",
                })}
                onClick={() => setGameType("all")}
              >
                <p>Tất cả</p>
              </div>
              <div
                className={classNames("key-item", {
                  active: game_type === "game_1",
                })}
                onClick={() => setGameType("game_1")}
              >
                <p>Nhanh 3</p>
              </div>
              <div
                className={classNames("key-item", {
                  active: game_type === "game_2",
                })}
                onClick={() => setGameType("game_2")}
              >
                <p>Màu thời gian</p>
              </div>
              <div
                className={classNames("key-item", {
                  active: game_type === "game_3",
                })}
                onClick={() => setGameType("game_3")}
              >
                <p>Màu may mắn</p>
              </div>
            </div> */}
            <div className="right">
              <ListGame />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default GamePage;
