import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faMoneyBillTransfer,
  faWallet,
  faRotate,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

import walletIcon from "assets/icons/wallet.svg";
import gameIcon from "assets/icons/game.svg";
import agencyIcon from "assets/icons/agency.svg";
import noticeIcon from "assets/icons/notice.svg";
import movieIcon from "assets/icons/movie.svg";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_fail_msg");
      NotificationManager.error(errorMessage);
    },
  });

  const getProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <div className="header_member">
        <div className="van-nav-bar">
          <div className="van-nav-bar__right">
            <FontAwesomeIcon
              icon={faCog}
              onClick={() => navigate("/member/setting")}
            />
          </div>
        </div>
        <div className="user-wrapper">
          <div className="user-avatar">
            <img src={user?.avatar_url} alt="" />
          </div>
          <div className="user-content">
            <h3 className="name">{user?.username}</h3>
            <div className="level">
              <span>VIP{user?.level}</span>
            </div>
          </div>
        </div>
        <div className="finance">
          <Link to="/payment/deposit" className="finance-item">
            <FontAwesomeIcon icon={faMoneyBillTransfer} />
            <p>{i18next.t("deposit")}</p>
          </Link>
          <Link to="/payment/withdraw" className="finance-item">
            <FontAwesomeIcon icon={faWallet} />
            <p>{i18next.t("withdraw")}</p>
          </Link>
        </div>
      </div>
      <div className="member-container">
        <div className="wallet">
          <div className="wallet-title">
            <p>{i18next.t("my_wallet")}</p>
          </div>
          <div className="wallet-balance">
            <h5 className="balance">{formatNumber(user_wallet?.amount)}</h5>
            <div className="action">
              <p>{i18next.t("balance")}</p>
              <button
                type="button"
                className="btn refresh-btn"
                onClick={() => window.location.reload()}
              >
                <FontAwesomeIcon icon={faRotate} />
              </button>
            </div>
          </div>
        </div>
        <div className="member-menu">
          <Link to="/histories/account" className="menu-item">
            <img src={walletIcon} alt="" />
            <div className="info">
              <h5>{i18next.t("history_account")}</h5>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/histories/deposit" className="menu-item">
            <img src={walletIcon} alt="" />
            <div className="info">
              <h5>{i18next.t("history_deposit")}</h5>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/histories/withdraw" className="menu-item">
            <img src={walletIcon} alt="" />
            <div className="info">
              <h5>{i18next.t("history_withdraw")}</h5>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/histories/game" className="menu-item">
            <img src={gameIcon} alt="" />
            <div className="info">
              <h5>{i18next.t("history_game")}</h5>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          {/* <div className="menu-item">
            <img src={agencyIcon} alt="" />
            <div className="info">
              <h5> Đại lý kiếm tiền</h5>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div> */}
          <Link to="/notice" className="menu-item">
            <img src={noticeIcon} alt="" />
            <div className="info">
              <h5>{i18next.t("notice")}</h5>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>

          <Link to="/histories/movie" className="menu-item">
            <img src={movieIcon} alt="" />
            <div className="info">
              <h5>{i18next.t("history_movie")}</h5>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
        </div>
        <div className="member-footer">
          <button
            type="button"
            className="btn btn-logout"
            onClick={() => onLogout()}
          >
            {i18next.t("logout")}
          </button>
        </div>
      </div>
    </>
  );
});

export default MemberPage;
