import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { GameApi } from "states/api/game";
import { useQuery } from "@tanstack/react-query";
import { gameTypeText } from "_common/constants/statusType";
import i18next from "i18next";

export default function GameHistory({ data, onClose }) {
  const { data: listData, refetch } = useQuery(
    ["game", "list", data?.room_id],
    () =>
      GameApi.getGames({
        params: {
          page: 1,
          limit: 20,
          status: "finish",
          level: data?.room_id,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const renderBody = (item) => {
    const level = item?.level;
    // if (level === "level_3")
    //   return (
    //     <>
    //       <div className="res-des">{gameTypeText[item?.bet1]}</div>
    //       <div className="res-des">{gameTypeText[item?.bet2]}</div>
    //     </>
    //   );

    // if (level === "level_2")
    //   return (
    //     <>
    //       <div className="res-ball small">{item?.number1}</div>
    //       <div className="res-des small">{item?.number1}</div>
    //       <div className="res-des small">{gameTypeText[item?.bet1]}</div>
    //       <div className="res-des small">{gameTypeText[item?.bet2]}</div>
    //     </>
    //   );

    return (
      <>
        <div className="res-des">{gameTypeText[item?.bet1]}</div>
        <div className="res-des">{gameTypeText[item?.bet2]}</div>
      </>
    );
  };

  return (
    <div className="game-history">
      <div className="history-mask" onClick={onClose} />
      <div className="history">
        <div className="wrapper">
          <div className="item even">
            <div className="left font-weight">{i18next.t("game_no")}</div>
            <div className="right font-weight">{i18next.t("result")}</div>
          </div>

          {listData?.data?.map((item) => (
            <div className="item odd" key={item?.id}>
              <div className="left">{item?.game_no}</div>
              <div className="right">
                <div className="ssc-ball center">{renderBody(item)}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
