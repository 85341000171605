import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";

export default function HotGame() {
  const navigate = useNavigate();
  const {
    authStore: { game_hot },
  } = useStores();

  return (
    <div className="hot-game">
      <div className="hot-title">
        <h2>{i18next.t("hot_title1")}</h2>
        <div className="action">
          <span onClick={() => navigate("/game")}>
            {i18next.t("view_more")}
          </span>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </div>
      <ul>
        {game_hot?.map((item) => (
          <li
            key={item?.id}
            role="presentation"
            onClick={() => navigate(`/game/lottery?code=${item?.room_id}`)}
          >
            <img src={item?.image_url} alt="" />
            <h5>{i18next.t(item?.name)}</h5>
          </li>
        ))}
      </ul>
    </div>
  );
}
