export const cityList = [
  "서울",
  "부산",
  "인천	",
  "대구",
  "대전",
  "광주",
  "수원",
  "울산",
  "용인",
  "고양",
  "창원",
  "성남",
];
