import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";

export default function ModalConfirm({ open, onClose }) {
  return (
    <Portal>
      <div className="modal modal-confirm" style={{ display: open && "block" }}>
        <div className="mask" onClick={onClose} />
        <div className="modal-content modal-bottom">
          <div className="content-body">
            <h4>{i18next.t("notice")}</h4>
            <p>{i18next.t("note19")}</p>
          </div>

          <div className="btn-group">
            <button type="button" className="btn btn-cancel" onClick={onClose}>
              {i18next.t("cancel")}
            </button>
            <button type="button" className="btn btn-confirm" onClick={onClose}>
              {i18next.t("ok")}
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
}
