import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";
import CityList from "./CityList";

const CityPage = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const city = params.get("city");

  const [active_tab, setActiveTab] = useState("tab1");
  return (
    <>
      <HeaderTitle title={i18next.t("girl")} />
      <div className="main-content">
        <div className="page-content">
          <div className="beauty-container">
            <div className="van-tabs">
              <div className="van-tabs__nav">
                <div
                  className={classNames("van-tab", {
                    active: active_tab === "tab1",
                  })}
                  onClick={() => navigate(`/beauty/city`)}
                >
                  <p>{i18next.t("city")}</p>
                </div>
                <div
                  className={classNames("van-tab", {
                    active: active_tab === "tab2",
                  })}
                  onClick={() => navigate(`/beauty?tab=tab2`)}
                >
                  <p>{i18next.t("introduce_text")}</p>
                </div>
              </div>
            </div>
            <p className="des">{i18next.t("note3")}</p>
            <CityList onMore={() => setActiveTab("tab2")} />
          </div>
        </div>
      </div>
    </>
  );
});

export default CityPage;
