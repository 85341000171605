import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { ProfileApi } from "states/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import classNames from "classnames";
import i18next from "i18next";

const UpdateInfoPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet },
  } = useStores();
  const [params] = useSearchParams();
  const type = params.get("type");

  const typeText = {
    full_name: i18next.t("edit_full_name"),
    nick_name: i18next.t("edit_nickname"),
  };

  const schema = yup.object({
    full_name: yup.string(),
    nick_name: yup.string("최대 8~16 문자 및 숫자를 입력하세요"),
    sex: yup.string(),
  });

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.update({ params: variables }),
    {
      onSuccess: (res) => {
        navigate("/member/information");
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("enter_required")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_fail_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = () => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate(formValue);
    }
  };

  useEffect(() => {
    if (!user) return;
    if (type === "full_name") {
      setValue("full_name", user?.full_name);
    }
    if (type === "nick_name") {
      setValue("nick_name", user?.nick_name);
    }
    if (type === "sex") {
      setValue("sex", user?.sex);
    }
  }, [user]);

  return (
    <>
      <HeaderTitle
        title={typeText[type]}
        isSave
        onSave={handleSubmit(onSave)}
      />
      <div className="update-info-container">
        <form className="form-action form-horizontal">
          {type === "full_name" && (
            <div
              className={classNames("form-item", {
                error: errors.full_name,
              })}
            >
              <div htmlFor="full_name" className="form_label">
                {i18next.t("action_success_msg")}
              </div>
              <div className="form_field">
                <input
                  className="field_control"
                  id="full_name"
                  type="text"
                  placeholder={i18next.t("enter_full_name")}
                  {...register("full_name")}
                />
                <span className="error_t">{errors.full_name?.message}</span>
              </div>
            </div>
          )}
          {type === "nick_name" && (
            <div
              className={classNames("form-item", {
                error: errors.nick_name,
              })}
            >
              <div htmlFor="nick_name" className="form_label">
                {i18next.t("nick_name")}
              </div>
              <div className="form_field">
                <input
                  className="field_control"
                  id="nick_name"
                  type="text"
                  placeholder={i18next.t("enter_nick_name")}
                  {...register("nick_name")}
                />

                <span className="error_t">{errors.nick_name?.message}</span>
              </div>
            </div>
          )}

          {type === "sex" && (
            <div className="menu-items">
              <div className="item">
                <label htmlFor="male">
                  <input
                    type="radio"
                    id="male"
                    name="fav_language"
                    value="male"
                    className="radio_control"
                    {...register("sex")}
                  />
                  <span>{i18next.t("male")}</span>
                </label>
              </div>
              <div className="item">
                <label htmlFor="female">
                  <input
                    type="radio"
                    id="female"
                    name="fav_language"
                    value="female"
                    className="radio_control"
                    {...register("sex")}
                  />
                  <span>{i18next.t("male")}</span>
                </label>
              </div>
              <div className="item">
                <label htmlFor="other">
                  <input
                    type="radio"
                    id="other"
                    name="fav_language"
                    value="other"
                    className="radio_control"
                    {...register("sex")}
                  />
                  <span>{i18next.t("other")}</span>
                </label>
              </div>
            </div>
          )}
        </form>
        {type === "full_name" && (
          <p className="note text-red">{i18next.t("full_name_note")}</p>
        )}
      </div>
    </>
  );
});

export default UpdateInfoPage;
