import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import bankIcon from "assets/img/icon/bank.png";
import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import Collapse from "_common/component/Collapse";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faQuestionCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { get } from "lodash";
import { PaymentApi } from "states/api/payment";
import InputPassword from "_common/component/InputPassword";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";

const WithdrawPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();
  const { pushNotify } = useNotify();

  const [amount, setAmount] = useState(0);
  const [wallet_password, setWalletPassword] = useState("");

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          amount,
          bank_method: "banking",
          wallet_password,
          token_id: 3,
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        setWalletPassword("");
        setAmount(0);
        queryClient.invalidateQueries(["get_profile"]);
        pushNotify({
          type: "withdraw",
        });
        navigate(`/payment/withdraw/success?money=${amount}`);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_fail_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleChangeInput = (event) => {
    const value = event?.target?.value;
    const format = value?.replaceAll(",", "")?.replaceAll(".", "");
    setAmount(Number(format));
  };

  const handleSubmit = () => {
    if (!amount) return null;
    if (amount < Number(general?.min_withdraw || 0))
      return NotificationManager.error(
        i18next.t("please_contact_cskh", {
          money: formatNumber(general?.min_withdraw),
        })
      );
    if (amount > Number(general?.max_withdraw || 0))
      return NotificationManager.error(
        i18next.t("please_contact_cskh", {
          money: formatNumber(general?.max_withdraw),
        })
      );
    onSubmit();
    return null;
  };

  const handleSelectAll = () => {
    setAmount(user_wallet?.amount);
  };

  const handleChangePassword = (event) => {
    setWalletPassword(event?.target?.value);
  };

  return (
    <>
      <HeaderTitle
        title={i18next.t("withdraw")}
        subTitle={
          <span onClick={() => navigate(`/histories/withdraw`)}>
            {i18next.t("history_withdraw")}
          </span>
        }
      />

      <div className="payment-container payment-withdraw-page">
        {get(user, "banks[0].bank_code") ? (
          <div to="/payment/bank/add" className="addBankCard">
            <span>
              {get(user, "banks[0].bank_code")} (
              {get(user, "banks[0].bank_number_display")})
            </span>
            <FontAwesomeIcon icon={faCheck} />
          </div>
        ) : (
          <Link to="/payment/bank/add" className="addBankCard">
            <FontAwesomeIcon icon={faPlus} />
            <span>{i18next.t("add_bank")}</span>
          </Link>
        )}

        <div className="payMoney">
          <div className="label">
            <span>{i18next.t("amount_withdraw")} (P)</span>
          </div>
          <div className="money">
            <input
              inputMode="numeric"
              pattern="[0-9]"
              placeholder={i18next.t("enter_amount")}
              className="control-number"
              value={amount ? formatNumber(amount) : ""}
              onChange={handleChangeInput}
            />
            <button
              type="button"
              className="btn btn-all"
              onClick={handleSelectAll}
            >
              {i18next.t("all")}
            </button>
          </div>
          <div className="money" style={{ paddingTop: 20 }}>
            <span>{i18next.t("wallet_pass")}</span>
            <InputPassword
              className="control-number"
              onChange={handleChangePassword}
            />
          </div>
          <div className="remark">
            <p>
              {i18next.t("withdraw_msg1", {
                money: formatNumber(general?.max_withdraw),
              })}
            </p>
            <p>
              {i18next.t("withdraw_msg2", {
                money: formatNumber(general?.min_withdraw),
              })}
            </p>
          </div>
          <div className="information">
            <div className="des-help">
              <div className="help-content">
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span>{i18next.t("note1")}</span>
              </div>
            </div>
            <div className="balance">
              <span>{i18next.t("balance")}:</span>
              <span>{formatNumber(user_wallet?.amount)}</span>
              <span>P</span>
            </div>
          </div>
        </div>
        <button
          type="button"
          className={classNames("btn btn-sub", {
            active: Number(amount || 0) >= Number(general?.min_withdraw || 0),
          })}
          onClick={handleSubmit}
        >
          {i18next.t("withdraw_submit")}
        </button>
      </div>
    </>
  );
});

export default WithdrawPage;
