import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationManager } from "react-notifications";
import { formatNumber } from "_common/utils/formatValue";
import { MovieApi } from "states/api/movie";
import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import i18next from "i18next";
import ModalConfirm from "./ModalConfirm";

export default function ListVideoDetail() {
  const vidRef = useRef(null);
  const navigate = useNavigate();
  const {
    authStore: { general, user },
  } = useStores();
  const [params] = useSearchParams();
  const code = params.get("code");

  const [openVideo, setOpenVideo] = useState(false);
  const [is_play, setIsPlay] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { data, refetch } = useQuery(
    ["movie", "detail", code],
    () =>
      MovieApi.getDetail({
        id: code,
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleSubmit = () => {
    try {
      if (is_play) {
        setOpenModal(true);
        return null;
      }
      vidRef.current?.play();
      setOpenVideo(true);
      setIsPlay(true);

      return null;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    try {
      if (user?.level > 1) return;
      const timeLimit = Number(get(general, "time_limit", 15)) * 1000;
      setTimeout(() => {
        setOpenVideo(false);
        setOpenModal(true);
        vidRef.current.pause();
        vidRef.current.removeAttribute("");
        vidRef.current.load();
        document.exitFullscreen();
      }, timeLimit);
    } catch (error) {
      console.error(error);
    }
  }, [user, general?.time_limit]);

  return (
    <>
      <HeaderTitle title={data?.name} />
      <div className="main-content">
        <div className="page-content">
          <div className="video-container">
            <div className="video-div">
              <div className="video-js">
                <video
                  ref={vidRef}
                  id="example-video_html5_api"
                  className="vjs-tech"
                  poster={data?.thumb_url}
                  controls
                  src={!is_play && data?.video_url}
                >
                  <track
                    default
                    kind="captions"
                    srcLang="en"
                    src={!is_play && data?.video_url}
                    type="video/mp4"
                  />
                </video>
                {!openVideo && (
                  <>
                    <img className="video-thumb" alt="" src={data?.thumb_url} />
                    <div className="play-button" onClick={handleSubmit}>
                      <FontAwesomeIcon icon={faPlay} />
                    </div>
                  </>
                )}
              </div>
              <h1>{data?.name}</h1>
              <p>
                {formatNumber(data?.count)} {i18next.t("de1")}
              </p>
            </div>
            <div className="movie-content">
              <div className="hot-title">
                <h2>{i18next.t("video12")}</h2>
              </div>
              <div className="movie-body">
                {data?.recommends.slice(0, 5).map((item) => (
                  <a
                    href={`/movie/detail?code=${item?.id}`}
                    key={item?.id}
                    className="item"
                  >
                    <div className="thumb">
                      <img alt="" src={item?.thumb_url} />
                      <span className="time">{item?.time}</span>
                    </div>
                    <div className="right">
                      <p>{item?.name}</p>
                      <p>
                        <span>
                          {item?.count} {i18next.t("de2")}
                        </span>
                      </p>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <ModalConfirm onClose={() => setOpenModal(false)} open={openModal} />
      </div>
    </>
  );
}
