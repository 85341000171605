import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";

import { AuthApi } from "states/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import InputPassword from "_common/component/InputPassword";
import i18next from "i18next";

export default function LoginComponent() {
  const { authStore } = useStores();
  const navigate = useNavigate();

  const schema = yup
    .object({
      username: yup.string().required(i18next.t("require_username_login")),
      password: yup.string().required(i18next.t("enter_pass_login")),
    })
    .required();

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data?.token);
        authStore.updateUser(res?.data?.user);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_fail_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <form className="auth-form-user" onSubmit={handleSubmit(handleLogin)}>
      <div>
        <ul>
          <li
            className={classNames("marginBottom", {
              error: errors.username,
            })}
          >
            <div className="form_In">
              <input
                className="field_control"
                id="username"
                type="text"
                placeholder={i18next.t("enter_username_login")}
                {...register("username")}
              />
              <span className="error_t">{errors.username?.message}</span>
            </div>
          </li>

          <li
            className={classNames("marginBottom", {
              error: errors.password,
            })}
          >
            <div className="form_In">
              <InputPassword
                className="field_control"
                id="password"
                placeholder={i18next.t("enter_password")}
                {...register("password")}
              />
              <span className="error_t">{errors.password?.message}</span>
            </div>
          </li>
        </ul>
        <p className="signin-other-title">
          {i18next.t("register_yet")}{" "}
          <Link
            style={{ color: "rgb(255,249,0)", fontWeight: 600 }}
            to="/auth/register"
          >
            {i18next.t("register_now")}
          </Link>
        </p>
        <div className="form_button">
          <input
            type="submit"
            className="btn btn-submit"
            value={i18next.t("login")}
          />
        </div>
      </div>
    </form>
  );
}
