import i18next from "i18next";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderLogin from "_common/component/Layout/Header/HeaderLogin";
import { useStores } from "_common/hooks";

// redux

import RegisterComponent from "./RegisterForm";

function RegisterPage() {
  const {
    authStore: { logo },
  } = useStores();

  return (
    <div className="remix-auth-page">
      <div className="bg-wrapper">
        <div className="auth-container">
          <img className="logo-img" src={logo} alt="Logo" />
          <RegisterComponent />
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
