import React from "react";
import { Routes, Route } from "react-router-dom";

import BasicLayout from "_common/component/Layout/BasicLayout";
import UnAuthLayout from "_common/component/Layout/UnAuthLayout";
import PageNotfound from "_common/component/PageNotfound";

// auth
import LoginPage from "pages/Auth/Login";
import RegisterPage from "pages/Auth/Register";

import { MiddlewareRouter } from "routers/MiddlewareRouter";
import CheckAuth from "routers/CheckAuth";
import HomePage from "pages/Home";
import GamePage from "pages/Game";
import MoviePage from "pages/Movie";
import MemberPage from "pages/Member";
import SettingPage from "pages/Member/Setting";
import InformationPage from "pages/Member/Information";
import UpdateInfoPage from "pages/Member/UpdateInfo";
import ChangePasswordPage from "pages/Member/ChangePassword";
import ChangeWalletPasswordPage from "pages/Member/ChangeWalletPassword";
import SettingGeneral from "pages/Member/SettingGeneral";
import SetLinePage from "pages/Member/SetLine";
import AboutPage from "pages/Member/About";
import ContactPage from "pages/Member/Contact";
import DepositPage from "pages/Payment/Deposit";
import DepositFormPage from "pages/Payment/Deposit/DepositFormPage";
import DepositBankInfo from "pages/Payment/Deposit/DepositBankInfo";
import DepositSuccessPage from "pages/Payment/Deposit/DepositSuccessPage";
import DepositHistoryPage from "pages/History/Deposit";
import DepositDetailPage from "pages/History/Deposit/Detail";
import WithdrawPage from "pages/Payment/Withdraw";
import BankPage from "pages/Payment/Bank";
import BankAddPage from "pages/Payment/Bank/BankAdd";
import WithdrawDetailPage from "pages/History/Withdraw/Detail";
import WithdrawHistoryPage from "pages/History/Withdraw";
import BeautyPage from "pages/Beauty";
import CityPage from "pages/Beauty/CityPage";
import BeautyDetailPage from "pages/Beauty/BeautyDetailPage";
import AccountHistoryPage from "pages/History/Account";
import NoticePage from "pages/Notice";
import MovieHistoryPage from "pages/History/Movie";
import ListVideoDetail from "pages/Movie/ListVideoDetail";
import UnAuthBasicLayout from "_common/component/Layout/BasicLayout/UnAuthBasicLayout";
import GameBoardPage from "pages/Game/GameBoard";
import GameLayout from "_common/component/Layout/BasicLayout/GameLayout";
import GameHistoryPage from "pages/History/Game";
import GameHistoryDetailPage from "pages/History/Game/Detail";
import CSKh from "pages/Member/CSKh";
import WithdrawSuccessPage from "pages/Payment/Withdraw/WithdrawSuccessPage";

export function Routers() {
  return (
    <Routes>
      <Route path="/" element={<UnAuthBasicLayout />}>
        <Route index element={<HomePage />} />
        <Route path="home" element={<HomePage />} />
        <Route path="cskh" element={<CSKh />} />
        <Route path="game" element={<GamePage />} />
        <Route path="/movie">
          <Route index element={<MoviePage />} />
          <Route path="detail" element={<ListVideoDetail />} />
        </Route>
        <Route path="notice" element={<NoticePage />} />
        <Route path="/beauty">
          <Route index element={<BeautyPage />} />
          <Route path="city" element={<CityPage />} />
          <Route path="detail" element={<BeautyDetailPage />} />
        </Route>
      </Route>
      <Route
        path="/"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route path="/member">
          <Route index element={<MemberPage />} />
          <Route path="setting" element={<SettingPage />} />
          <Route path="information" element={<InformationPage />} />
          <Route path="update-info" element={<UpdateInfoPage />} />
          <Route path="change-password" element={<ChangePasswordPage />} />
          <Route path="setting-general" element={<SettingGeneral />} />
          <Route path="set-line" element={<SetLinePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route
            path="change-wallet-password"
            element={<ChangeWalletPasswordPage />}
          />
        </Route>
        <Route path="/payment">
          <Route path="deposit" element={<DepositFormPage />} />
          <Route path="deposit/submit" element={<DepositFormPage />} />
          <Route path="deposit/bank-info" element={<DepositBankInfo />} />
          <Route path="deposit/success" element={<DepositSuccessPage />} />
          <Route path="withdraw" element={<WithdrawPage />} />
          <Route path="withdraw/success" element={<WithdrawSuccessPage />} />
          <Route path="bank" element={<BankPage />} />
          <Route path="bank/add" element={<BankAddPage />} />
        </Route>
        <Route path="/histories">
          <Route path="movie" element={<MovieHistoryPage />} />
          <Route path="game" element={<GameHistoryPage />} />
          <Route path="game/detail" element={<GameHistoryDetailPage />} />
          <Route path="account" element={<AccountHistoryPage />} />
          <Route path="deposit" element={<DepositHistoryPage />} />
          <Route path="deposit/detail" element={<DepositDetailPage />} />
          <Route path="withdraw" element={<WithdrawHistoryPage />} />
          <Route path="withdraw/detail" element={<WithdrawDetailPage />} />
        </Route>
      </Route>
      <Route
        path="/game"
        element={
          <MiddlewareRouter>
            <GameLayout />
          </MiddlewareRouter>
        }
      >
        <Route path="lottery" element={<GameBoardPage />} />
      </Route>
      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
}
