import i18next from "i18next";
import React from "react";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

function AccountHistoryPage() {
  const {
    authStore: { user },
  } = useStores();
  return (
    <>
      <HeaderTitle title={i18next.t("account_report")} />
      <div className="container_main account_report">
        <div className="report-head">
          <h5>{i18next.t("my_report")}</h5>
          <h4>{formatNumber(user?.total_profit)}</h4>
          <h5>{i18next.t("profit")}</h5>
        </div>
        <div className="report-swapper">
          <div className="item">
            <h4>{formatNumber(user?.total_bet)}</h4>
            <h5>{i18next.t("total_bet")}</h5>
          </div>
          <div className="item">
            <h4>{formatNumber(user?.bill_deposit)}</h4>
            <h5>{i18next.t("bill_deposit")}</h5>
          </div>
          <div className="item">
            <h4>{formatNumber(user?.bill_withdraw)}</h4>
            <h5>{i18next.t("bill_withdraw")}</h5>
          </div>
          <div className="item">
            <h4>{formatNumber(user?.total_win)}</h4>
            <h5>{i18next.t("total_win")}</h5>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountHistoryPage;
