import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GirlApi } from "states/api/girl";
import NoData from "_common/component/NoData";
import { useStores } from "_common/hooks";

export default function GirlList() {
  const navigate = useNavigate();
  const {
    authStore: { girls },
  } = useStores();
  const [params] = useSearchParams();
  const city = params.get("city");

  const { data, refetch } = useQuery(
    ["girl", "list", city],
    () =>
      GirlApi.getList({
        params: {
          page: 1,
          limit: 10,
          city,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  if (!data?.data?.length) return <NoData description={i18next.t("no_data")} />;

  return (
    <div className="girl-container">
      <div className="list-girl">
        {data?.data?.map((item) => (
          <div
            className="item"
            key={item?.id}
            onClick={() => navigate(`/beauty/detail?code=${item?.id}`)}
          >
            <h5>{item?.code || `A00${item?.id}`}</h5>
            <img src={item?.image_url} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
}
