import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { PaymentApi } from "states/api/payment";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import {
  gameLevelText,
  gameStatusText,
  gameTypeText,
  paymentStatusText,
} from "_common/constants/statusType";
import { formatNumber } from "_common/utils/formatValue";
import { GameApi } from "states/api/game";
import i18next from "i18next";

function GameHistoryDetailPage() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const id = params.get("id");

  const { data, refetch } = useQuery(
    ["game", "detail", id],
    () => GameApi.getDetail({ id }),
    {
      staleTime: 300000,
      id,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const renderResult = useMemo(() => {
    if (!data) return <span />;
    const game = data?.game;
    return (
      <div>
        {gameTypeText[game?.bet1]} | {gameTypeText[game?.bet2]}
      </div>
    );

    // if (game_level === "level_3")
    //   return (
    //     <div>
    //       {gameTypeText[game?.bet1]} | {gameTypeText[game?.bet2]}
    //     </div>
    //   );
    // if (game_level === "level_2")
    //   return (
    //     <div>
    //       {game?.number1} | {gameTypeText[game?.bet2]} |
    //       {gameTypeText[game?.bet1]}
    //     </div>
    //   );

    // return (
    //   <div>
    //     {game?.number1} , {game?.number2} , {game?.number3} , {game?.number4} ,{" "}
    //     {game?.number5} | {game?.total_number} | {gameTypeText[game?.bet1]} |
    //     {gameTypeText[game?.bet2]}
    //   </div>
    // );
  }, [data]);

  return (
    <>
      <HeaderTitle title={i18next.t("game_detail")} />
      <div className="container_main">
        <div className="histories ">
          <div className="main-info">
            <div className="item">
              <span className="label">{i18next.t("game_no")}:</span>
              <span className="text">{data?.game?.game_no}</span>
            </div>
            <div className="item">
              <span className="label">{i18next.t("bet_time")}:</span>
              <span className="text">
                {moment(data?.created_at).format("DD/MM/YYYY HH:mm:ss")}
              </span>
            </div>
            <div className="item">
              <span className="label">{i18next.t("type")}:</span>
              <span className="text">{i18next.t(data?.game_bet)}</span>
            </div>
            <div className="item">
              <span className="label">{i18next.t("status")}:</span>
              <span className="text">{i18next.t(data?.status)}</span>
            </div>
            <div className="item">
              <span className="label">{i18next.t("bet_money")}:</span>
              <span className="text">{formatNumber(data?.amount)} P</span>
            </div>
            <div className="item">
              <span className="label">{i18next.t("payback_amount")}:</span>
              <span className="text">
                {formatNumber(data?.amount_payback)} P
              </span>
            </div>
            <div className="item">
              <span className="label">{i18next.t("result")}:</span>
              <span className="text">{renderResult}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GameHistoryDetailPage;
