import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import bankIcon from "assets/img/icon/bank.png";
import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BankApi } from "states/api/bank";
import i18next from "i18next";

const BankAddPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, select_bank, general },
  } = useStores();

  const schema = yup
    .object({
      bank_owner: yup.string().required(i18next.t("enter_required")),
      bank_number: yup.string().required(i18next.t("enter_required")),
      bank_code: yup.string().required(i18next.t("enter_required")),
    })
    .required();

  const { mutate, isLoading } = useMutation(
    (variables) => {
      if (select_bank?.id)
        return BankApi.update({ id: select_bank?.id, params: variables });
      return BankApi.create({ params: variables });
    },
    {
      onSuccess: (res) => {
        navigate("/");
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_fail_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!select_bank?.id) return;
    setValue("bank_owner", select_bank?.bank_owner);
    setValue("bank_code", select_bank?.bank_code);
    setValue("bank_number", select_bank?.bank_number);
  }, [select_bank]);

  const onSave = (values) => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate({
        ...values,
        bank_name: values?.bank_code,
      });
    }
  };

  return (
    <>
      <HeaderTitle title={i18next.t("link_bank_title")} />

      <div className="payment-container bank-add-page">
        <form
          className="form-action form-horizontal"
          onSubmit={handleSubmit(onSave)}
        >
          <div className="label">{i18next.t("note10")}</div>
          <div
            className={classNames("form-item", {
              error: errors.bank_owner,
            })}
          >
            <div htmlFor="bank_owner" className="form_label">
              {i18next.t("full_name")}
            </div>
            <div className="form_field">
              <input
                className="field_control"
                type="text"
                id="bank_owner"
                placeholder={i18next.t("enter_bank_owner")}
                {...register("bank_owner")}
              />
              <span className="error_t">{errors.bank_owner?.message}</span>
            </div>
          </div>
          <div className="line" />
          <div className="label">{i18next.t("note11")}</div>
          <div
            className={classNames("form-item", {
              error: errors.bank_number,
            })}
          >
            <div htmlFor="bank_number" className="form_label">
              {i18next.t("bank_number")}
            </div>
            <div className="form_field">
              <input
                className="field_control"
                type="text"
                id="bank_number"
                placeholder={i18next.t("은행 계좌번호를 입력하세요 ")}
                {...register("bank_number")}
              />
              <span className="error_t">{errors.bank_number?.message}</span>
            </div>
          </div>
          <div
            className={classNames("form-item", {
              error: errors.bank_code,
            })}
          >
            <div htmlFor="bank_code" className="form_label">
              {i18next.t("bank_name")}
            </div>
            <div className="form_field">
              <input
                className="field_control"
                type="text"
                id="bank_code"
                placeholder={i18next.t("은행명을 입력하세요")}
                {...register("bank_code")}
              />
              <span className="error_t">{errors.bank_code?.message}</span>
            </div>
          </div>
          <p className="text-red">{i18next.t("note12")}</p>
          <button
            type="submit"
            className={classNames("btn btn-sub", {
              active: true,
            })}
          >
            {i18next.t("btn_add_bank")}
          </button>
        </form>
      </div>
    </>
  );
});

export default BankAddPage;
