import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { formatTimeSecond } from "_common/utils/datetimeFormat";
import { get } from "lodash";
import i18next from "i18next";

export default function HeaderGame({
  data,
  dataGame,
  open_history,
  setOpenHistory,
}) {
  return (
    <div className="record">
      <div className="period">
        <img className="cover" src={data?.image_url} alt="" />
        <div className="period-number">
          {dataGame?.game_no}
          {i18next.t("game_no")}
        </div>
        <div className="van-count-down">
          <div className="clock">{formatTimeSecond(dataGame?.time)}</div>
        </div>
      </div>
      <div className="linear-gradient " />
      {dataGame?.prev_game && (
        <div className="recent" onClick={() => setOpenHistory(!open_history)}>
          <div className="ssc-ball left">
            {/* {get(dataGame, "prev_game.result", []).map((item, index) => (
              <div
                key={`item-${index?.toString()}`}
                className="res-ball middle"
              >
                {item}
              </div>
            ))} */}
            {/* {data?.room_id === "level_2" && (
              <div className="res-des middle">
                {get(dataGame, "prev_game.result[0]")}
              </div>
            )}
            {get(dataGame, "prev_game.total") && (
              <div className="res-des middle">
                {get(dataGame, "prev_game.total")}
              </div>
            )} */}
            <div className="res-des middle">
              {data?.bet_type[get(dataGame, "prev_game.bet1")]}
            </div>
            <div className="res-des middle">
              {data?.bet_type[get(dataGame, "prev_game.bet2")]}
            </div>
            {/* {get(dataGame, "prev_game.bet3") && (
              <div className="res-des middle">
                {`Ngưu ${get(dataGame, "prev_game.bet3")}`}
              </div>
            )} */}
          </div>
          <FontAwesomeIcon icon={open_history ? faAngleUp : faAngleDown} />
        </div>
      )}
    </div>
  );
}
