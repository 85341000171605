import React from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "_common/utils/formatValue";

function HeaderTop({ isBack = true, isLogo, isNotice, title }) {
  const {
    authStore: { general, user },
  } = useStores();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <div className="home-top-bar">
      <div className="logo-div">
        {title && <h5>{title}</h5>}
        {isLogo && <img className="logo-img" src={general?.logo} alt="" />}
      </div>
      {isNotice && (
        <Link to="/notice" className="notice-icon">
          <span>{formatNumber(user?.total_notice)}</span>
          <FontAwesomeIcon icon={faCommentDots} />
        </Link>
      )}
    </div>
  );
}

export default HeaderTop;
