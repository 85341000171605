import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { Portal } from "react-portal";
import classNames from "classnames";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProfileApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";

export default function ModalUpdateAvatar({ open, onClose }) {
  const queryClient = useQueryClient();
  const {
    authStore: { avatars },
  } = useStores();

  const [selected, setSelected] = useState();

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.update({ params: variables }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        onClose();
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_fail_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = () => {
    if (!selected) return NotificationManager.error(i18next.t("enter_avatar"));
    const item = avatars.find((obj) => obj?.id === selected);

    if (item) {
      mutate({
        avatar: item?.image_url,
      });
    }

    return null;
  };

  return (
    <Portal>
      <div className="modal" style={{ display: open && "block" }}>
        <div className="mask" onClick={onClose} />
        <div className="modal-content modal-bottom">
          <div className="avatarbox">
            <div className="title">
              <div className="btn" onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <div className="text">{i18next.t("select_avatar")}</div>
              <div className="btn" onClick={handleSubmit}>
                {i18next.t("confirm")}
              </div>
            </div>
            <div className="list-avatar">
              {avatars?.map((item) => (
                <div
                  className={classNames("image", {
                    selected: selected === item?.id,
                  })}
                  key={item?.id}
                  onClick={() => setSelected(item?.id)}
                >
                  <img src={item?.image_url} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}
