import i18next from "i18next";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";

export default function ListGame() {
  const navigate = useNavigate();
  const {
    authStore: { game_hot },
  } = useStores();

  return (
    <div className="list-game">
      {game_hot.map((item) => (
        <Link
          to={`/game/lottery?code=${item?.room_id}`}
          key={item?.id}
          className="game-item"
        >
          <img alt="" src={item?.image_url} />
          <p>{i18next.t(item?.room_id)}</p>
        </Link>
      ))}
    </div>
  );
}
