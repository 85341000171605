import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useQueryClient } from "@tanstack/react-query";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCoins } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";

const DepositSuccessPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();
  const [params] = useSearchParams();
  const money = params.get("money");

  const handleSubmit = () => {
    navigate("/member");
  };

  return (
    <>
      <HeaderTitle title={i18next.t("deposit_success")} />

      <div className="payment-container  bank-info">
        <div className="info">
          <FontAwesomeIcon icon={faCheckCircle} />
          <div>
            <p>{i18next.t("note15")}</p>
            <p className="des">
              {i18next.t("money")}: {formatNumber(money)}P
            </p>
          </div>
        </div>
        <div className="note">
          <FontAwesomeIcon icon={faCoins} />
          <div>
            <p className="title">{i18next.t("note16")}</p>
            <p>{i18next.t("note17")}</p>
            <p>
              {i18next.t("note18")} <Link to="/">{i18next.t("cskh")}</Link>
            </p>
          </div>
        </div>
        <button
          type="button"
          className={classNames("btn btn-sub active")}
          onClick={handleSubmit}
        >
          {i18next.t("complete")}
        </button>
      </div>
    </>
  );
});

export default DepositSuccessPage;
