export const paymentStatusText = {
  pending: "Đang chờ xem xét",
  complete: "Hoàn thành",
  error: "Từ chối",
  reject: "Từ chối",
};

export const paymentStatusColor = {
  pending: "#ff8c1a",
  complete: "green",
  error: "red",
};

export const gameStatusText = {
  betGame: "Đang cược",
  win: "Đã trúng thưởng",
  loss: "Chưa trúng thưởng",
};

export const gameTypeText = {
  code1: "사랑",
  code2: "맥주",
  code3: "로켓",
  code4: "장미",
};

export const gameLevelText = {
  level_1: "이벤트1",
  level_2: "이벤트2",
  level_3: "이벤트3",
};
