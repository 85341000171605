import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";

import i18next from "i18next";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import ModalUpdateAvatar from "./ModalUpdateAvatar";

const sexText = {
  male: "Nam",
  female: "Nữ",
  other: "Không xác định",
};

const InformationPage = observer(() => {
  const {
    authStore: { user, user_wallet },
  } = useStores();

  const [openModalAvatar, setModalAvatar] = useState();

  return (
    <>
      <HeaderTitle title={i18next.t("my_information")} />
      <div className="information-container">
        <div className="menu-items">
          <div className="item " onClick={() => setModalAvatar(true)}>
            <div className="left">{i18next.t("avatar")}</div>
            <div className="right">
              <img className="avatar" src={user?.avatar_url} alt="" />
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <Link to="/member/update-info?type=full_name" className="item ">
            <div className="left">{i18next.t("full_name")}</div>
            <div className="right">
              <span>{user?.full_name || i18next.t("not_setting")}</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/update-info?type=sex" className="item ">
            <div className="left">{i18next.t("sex")}</div>
            <div className="right">
              <span>{i18next.t(user?.sex)}</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/update-info?type=nick_name" className="item ">
            <div className="left">{i18next.t("nick_name")}</div>
            <div className="right">
              <span>{user?.nick_name || i18next.t("not_setting")}</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/payment/bank" className="item ">
            <div className="left">{i18next.t("bank_link")}</div>
            <div className="right">
              <span>
                {get(user, "banks[0].bank_number")
                  ? i18next.t("settled")
                  : i18next.t("not_setting")}
              </span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          {/* <div className="item ">
            <div className="left">{i18next.t("phone")}</div>
            <div className="right">
              <span>{user?.phone_display}</span>
            </div>
          </div> */}
        </div>
      </div>
      {openModalAvatar && (
        <ModalUpdateAvatar
          open={openModalAvatar}
          onClose={() => setModalAvatar(false)}
        />
      )}
    </>
  );
});

export default InformationPage;
