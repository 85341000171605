import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import bankIcon from "assets/img/icon/bank.png";
import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import Collapse from "_common/component/Collapse";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import { PaymentApi } from "states/api/payment";
import { useNotify } from "_common/component/NotifyProvider";

const DepositFormPage = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pushNotify } = useNotify();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  const [amount, setAmount] = useState("");

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          amount,
          bank_method: "banking",
          token_id: 3,
          type: "deposit",
        },
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
        // navigate(`/payment/deposit/success?money=${amount}`);
        NotificationManager.success(i18next.t("please_contact_cskh"));
        pushNotify({
          type: "deposit",
        });
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_fail_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleChangeInput = (event) => {
    const value = event?.target?.value;
    const format = value?.replaceAll(",", "")?.replaceAll(".", "");
    setAmount(Number(format));
  };

  const handleSubmit = () => {
    if (amount < Number(general?.min_deposit || 0))
      return NotificationManager.error(
        i18next.t("please_contact_cskh", {
          money: formatNumber(general?.min_deposit),
        })
      );
    if (amount > Number(general?.max_deposit || 0))
      return NotificationManager.error(
        i18next.t("please_contact_cskh", {
          money: formatNumber(general?.max_deposit),
        })
      );
    onSubmit();
    return null;
  };

  return (
    <>
      <HeaderTitle title={i18next.t("deposit")} />

      <div className="payment-container payment-form-page">
        {/* <div className="payType">
          <div className="title-cell">
            <img src={bankIcon} alt="" />
            <span> Vui lòng liên hệ bộ phận chăm sóc khách hàng </span>
          </div>
          <div className="description">
            <p> Vui lòng liên hệ bộ phận chăm sóc khách hàng </p>
          </div>
        </div> */}
        <div className="payMoney">
          <div className="label">
            <span>{i18next.t("deposit_amount")}:</span>
          </div>
          <div className="money">
            <input
              inputMode="numeric"
              pattern="[0-9]"
              placeholder={i18next.t("enter_amount")}
              className="control-number"
              value={amount ? formatNumber(amount) : ""}
              onChange={handleChangeInput}
            />
          </div>
          <div className="remark">
            <p>
              {i18next.t("deposit_msg1", {
                money: formatNumber(general?.max_deposit),
              })}
            </p>
            <p>
              {i18next.t("deposit_msg2", {
                money: formatNumber(general?.min_deposit),
              })}
            </p>
          </div>
          {/* <div className="moneyNumber">
            <button
              type="button"
              onClick={() => handleSelect(1000000)}
              className={classNames("btn", {
                active: selectAmount === 1000000,
              })}
            >
              1tr P
            </button>
            <button
              type="button"
              onClick={() => handleSelect(5000000)}
              className={classNames("btn", {
                active: selectAmount === 5000000,
              })}
            >
              5tr P
            </button>
            <button
              type="button"
              onClick={() => handleSelect(10000000)}
              className={classNames("btn", {
                active: selectAmount === 10000000,
              })}
            >
              10tr P
            </button>
          </div> */}
          <div className="balance">
            <span>{i18next.t("balance")}:</span>
            <span className="text-red">
              {formatNumber(user_wallet?.amount)}
            </span>
          </div>
          <div className="service">
            <span>{i18next.t("note2")} </span>
          </div>
          <button
            type="button"
            className={classNames("btn btn-sub", {
              active: Number(amount || 0) >= Number(general?.min_deposit || 0),
            })}
            onClick={handleSubmit}
          >
            {i18next.t("next")}
          </button>
        </div>
      </div>
    </>
  );
});

export default DepositFormPage;
