import i18next from "i18next";
import React from "react";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import NoData from "_common/component/NoData";
import { useStores } from "_common/hooks";

function MovieHistoryPage() {
  const {
    authStore: { user },
  } = useStores();

  return (
    <>
      <HeaderTitle title={i18next.t("my_movie")} />
      <div className="container_main">
        <div className="histories ">
          <div className="account-card">
            <div className="left">
              <img src={user?.avatar_url} alt="" />
              <div>
                <p>
                  {i18next.t("note7", {
                    username:
                      user?.nick_name || user?.full_name || user?.username,
                  })}
                </p>
              </div>
            </div>
            <div className="right">
              <button type="button" className="btn-ticket">
                {i18next.t("note8")}
              </button>
            </div>
          </div>
          <h3 className="title" style={{ marginTop: 15 }}>
            {i18next.t("history_swap")}
          </h3>
          <NoData description={i18next.t("no_data")} />
        </div>
      </div>
    </>
  );
}

export default MovieHistoryPage;
