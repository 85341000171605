import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { get } from "lodash";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";

export default function InputGame({
  data,
  dataGame,
  lots = [],
  setLot,
  pushNotify,
}) {
  const queryClient = useQueryClient();
  const {
    authStore: { user_wallet, general },
  } = useStores();

  const [show_balance, setShowBalance] = useState(true);
  const [amount, setAmount] = useState(
    Number(get(general, "bet_default", 20000))
  );
  const [final_total, setFinalTotal] = useState(0);

  const { mutate: onSubmit } = useMutation(
    () =>
      GameApi.betGame({
        params: {
          game_id: dataGame?.game_id,
          game: data,
          items: lots,
          final_total,
          amount,
          type: "bet",
        },
      }),
    {
      onSuccess: (res) => {
        if (typeof pushNotify === "function") {
          pushNotify({
            type: "betGame",
            status: "new_bet",
          });
        }
        setAmount(Number(get(general, "bet_default", 20000)));
        setLot([]);
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(
          res?.data?.msg || i18next.t("bet_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_fail_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleChangeInput = (event) => {
    const value = event?.target?.value;
    const format = value?.replaceAll(",", "")?.replaceAll(".", "");
    if (value) {
      setAmount(Number(format));
    } else {
      setAmount("");
    }
  };

  useEffect(() => {
    const lot = lots?.length;
    setFinalTotal(lot * amount);
  }, [amount, lots]);

  const handleSubmitForm = () => {
    if (!lots?.length) {
      NotificationManager.error(i18next.t("bet_msg1"));
      return null;
    }

    if (final_total > 0) {
      onSubmit();
    }

    return null;
  };

  return (
    <div className="bottom-bar">
      <div className="bar">
        <div className="mid">
          <p className="text">{i18next.t("balance_bet")}</p>
          <p className="balance">
            {show_balance ? formatNumber(user_wallet?.amount) : "***"} P
            <FontAwesomeIcon
              icon={show_balance ? faEyeSlash : faEye}
              onClick={() => setShowBalance(!show_balance)}
            />
          </p>
        </div>
        <div className="right">
          <button
            type="button"
            className="btn btn-vote"
            onClick={() => handleSubmitForm()}
          >
            {i18next.t("confirm")}
          </button>
        </div>
      </div>
      <div
        className={classNames("wrapper ", {
          active: lots?.length > 0,
        })}
      >
        <div className="item">
          <span className="label">{i18next.t("bet1")}:</span>
          <div className="bet-number">
            {lots?.map((key) => data?.bet_type[key])?.join(",")}
          </div>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </div>
        <div className="item">
          <span className="label">{i18next.t("bet2")}:</span>
          <div className="amount-wrapper">
            <input
              className="field_control"
              inputMode="numeric"
              pattern="[0-9]"
              placeholder={i18next.t("enter_amount")}
              value={amount ? formatNumber(amount) : ""}
              onChange={handleChangeInput}
            />
            <span className="label">P</span>
          </div>
        </div>
        <div className="item van-hairline--top">
          <div className="part">
            <span>{i18next.t("total")}</span>
            <span className="number">{lots?.length}</span>
            <span>{i18next.t("bet")}</span>
          </div>
          <div className="part">
            <span>{i18next.t("all")}</span>
            <span className="number">{formatNumber(final_total)}</span>
            <span>P</span>
          </div>
        </div>
      </div>
    </div>
  );
}
