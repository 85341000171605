import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { MovieApi } from "states/api/movie";
import NoData from "_common/component/NoData";
import i18next from "i18next";

export default function ListVideo({ type }) {
  const navigate = useNavigate();
  const {
    authStore: { videos },
  } = useStores();

  const { data, refetch } = useQuery(
    ["movie", "list", type],
    () =>
      MovieApi.getList({
        params: {
          page: 1,
          limit: 140,
          type,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  if (!data?.data?.length) return <NoData description={i18next.t("no_data")} />;

  return (
    <div className="list-video">
      {data?.data?.map((item) => (
        <Link
          to={`/movie/detail?code=${item?.id}`}
          key={item?.id}
          className="item"
        >
          <img alt="" src={item?.thumb_url} />
          <p className="movie-time">{item?.name}</p>
        </Link>
      ))}
    </div>
  );
}
