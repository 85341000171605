import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";
import GirlList from "./GirlList";
import Intro from "./Intro";

const BeautyPage = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const city = params.get("city");
  const tab = params.get("tab");

  const [active_tab, setActiveTab] = useState("tab1");

  useEffect(() => {
    if (!tab) return;
    setActiveTab(tab);
  }, [tab]);

  return (
    <>
      <HeaderTitle title={i18next.t("girl")} />
      <div className="main-content">
        <div className="page-content">
          <div className="beauty-container">
            <div className="van-tabs">
              <div className="van-tabs__nav">
                <div
                  className={classNames("van-tab", {
                    active: active_tab === "tab1",
                  })}
                  onClick={() => navigate(`/beauty/city`)}
                >
                  <p>{i18next.t("city")}</p>
                </div>
                <div
                  className={classNames("van-tab", {
                    active: active_tab === "tab2",
                  })}
                  onClick={() => setActiveTab("tab2")}
                >
                  <p>{i18next.t("introduce_text")}</p>
                </div>
              </div>
            </div>
            {active_tab === "tab1" && (
              <p className="des">{i18next.t("note3")}</p>
            )}
            {active_tab === "tab2" && (
              <p className="des">{i18next.t("note23")}</p>
            )}
            {active_tab === "tab1" && <GirlList />}
            {active_tab === "tab2" && <Intro />}
          </div>
        </div>
      </div>
    </>
  );
});

export default BeautyPage;
