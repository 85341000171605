import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import React, { useEffect } from "react";
import { NotificationApi } from "states/api/notification";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";

function NoticePage() {
  const queryClient = useQueryClient();
  const {
    authStore: { user },
  } = useStores();
  const { data, refetch } = useQuery(
    ["notice", "list"],
    () =>
      NotificationApi.getList({
        params: {
          page: 1,
          limit: 50,
          user_id: user?.id,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      NotificationApi.read({
        params: {
          user_id: user?.id,
        },
      }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["get_profile"]);
      },
    }
  );

  useEffect(() => {
    refetch();
    onSubmit();
  }, []);

  return (
    <>
      <HeaderTitle title={i18next.t("notice_title")} />
      <div className="container_main">
        <div className="notice-list ">
          {data?.data?.map((item) => (
            <div className="item">
              <h5>{item?.title}</h5>
              <p>{item?.content}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default NoticePage;
