import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

export default function ListBanner() {
  const navigate = useNavigate();
  const {
    authStore: { banners },
  } = useStores();

  return (
    <div className="slide-home">
      <Swiper
        pagination
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="swiper2 "
      >
        {banners.map((item) => (
          <SwiperSlide key={item?.id}>
            {item.type === "image" && (
              <a href="/">
                <img alt="" src={item?.image_url} />
              </a>
            )}
            {item.type === "video" && (
              // <iframe
              //   width="100%"
              //   height="200"
              //   src={item?.src}
              //   frameBorder="0"
              //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              //   allowFullScreen
              //   title="video"
              // />
              <video
                width="100%"
                loop="true"
                // autoPlay="autoplay"
                controls
                muted
              >
                <source src={item?.src} type="video/mp4" />
              </video>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
