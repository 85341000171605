import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";
import { useSearchParams } from "react-router-dom";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import HeaderGame from "./HeaderGame";
import OptionData from "./OptionData";
import InputGame from "./InputGame";
import GameHistory from "./GameHistory";

const GameBoardPage = observer(() => {
  const [params, setParams] = useSearchParams();
  const {
    authStore: { game_hot },
  } = useStores();
  const { dataGame, newResult, pushNotify } = useNotify();

  const code = params.get("code");
  const [open_history, setOpenHistory] = useState(false);
  const [lots, setLot] = useState([]);

  const data = useMemo(() => {
    if (!code) return {};
    return game_hot?.find((obj) => obj?.room_id === code);
  }, [code]);

  return (
    <>
      <HeaderTitle title={i18next.t(data?.room_id)} />
      <div className="main-content">
        <div className="page-content">
          <div className="game-board">
            <HeaderGame
              data={data}
              dataGame={dataGame}
              open_history={open_history}
              setOpenHistory={setOpenHistory}
            />
            <div className="wrapper">
              <OptionData data={data} lots={lots} onSelect={setLot} />
              <InputGame
                dataGame={dataGame}
                data={data}
                lots={lots}
                setLot={setLot}
                pushNotify={pushNotify}
              />
              {open_history && (
                <GameHistory
                  data={data}
                  onClose={() => setOpenHistory(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default GameBoardPage;
