import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import classNames from "classnames";
import InputPassword from "_common/component/InputPassword";
import i18next from "i18next";

export default function RegisterComponent() {
  const { authStore } = useStores();
  const [params] = useSearchParams();
  const schema = yup
    .object({
      username: yup
        .string()
        .min(6, i18next.t("require_username_login"))
        .required(i18next.t("require_username_login")),
      password: yup.string().required(i18next.t("enter_pass_login")),
      ref_no: yup.string().required(i18next.t("enter_ref_no_require")),
    })
    .required();

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data?.token);
        authStore.updateUser(res?.data?.user);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_fail_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <form className="auth-form-user" onSubmit={handleSubmit(handleRegister)}>
      <div>
        <ul>
          <li
            className={classNames("marginBottom", {
              error: errors.username,
            })}
          >
            <div className="form_In">
              <input
                className="field_control"
                id="username"
                type="text"
                placeholder={i18next.t("enter_username")}
                {...register("username")}
              />
              <span className="error_t">{errors.username?.message}</span>
            </div>
          </li>
          <li
            className={classNames("marginBottom", {
              error: errors.password,
            })}
          >
            <div className="form_In">
              <InputPassword
                className="field_control"
                id="password"
                placeholder={i18next.t("enter_password")}
                {...register("password")}
              />
              <span className="error_t">{errors.password?.message}</span>
            </div>
          </li>
          <li
            className={classNames("marginBottom", {
              error: errors.ref_no,
            })}
          >
            <div className="form_In">
              <input
                className="field_control"
                id="ref_no"
                type="text"
                placeholder={i18next.t("enter_ref_no")}
                {...register("ref_no")}
              />
              <span className="error_t">{errors.ref_no?.message}</span>
            </div>
          </li>
        </ul>
        <p className="signin-other-title">
          {i18next.t("login_yet")}{" "}
          <Link
            to="/auth/login"
            style={{ color: "rgb(255,249,0)", fontWeight: 600 }}
          >
            {i18next.t("login_now")}
          </Link>
        </p>
        <div className="form_button">
          <button type="submit" className="btn btn-submit">
            {i18next.t("register")}
          </button>
        </div>
      </div>
    </form>
  );
}
