import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp, faHeadphones } from "@fortawesome/free-solid-svg-icons";
import iconVolume from "assets/img/icon/volume.png";

function randomString(len, charSet) {
  charSet = charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let randomString = "";
  for (let i = 0; i < len; i += 1) {
    const randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
}

export default function Notice() {
  const navigate = useNavigate();
  const {
    authStore: { banners },
  } = useStores();

  const [ran, setRan] = useState();

  const renderItem = () => {
    function randomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const idUser = randomString(2);

    const result = `ID (${idUser}****)회원님 매칭 성공 하신것을 축하드립니다 !!!`;
    setRan(result);
  };

  useEffect(() => {
    renderItem();
    const interval = setInterval(() => {
      renderItem();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="notice-bar">
      <div className="wrapper">
        <div className="icon-notice">
          <img src={iconVolume} alt="" />
        </div>
        <div className="notice">
          <p>{ran}</p>
        </div>
        {/* <div className="icon-cskh" onClick={() => navigate("/cskh")}>
          <img src={cskh} alt="" />
        </div> */}
      </div>
    </div>
  );
}
