import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import i18next from "i18next";

const SettingPage = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_fail_msg");
      NotificationManager.error(errorMessage);
    },
  });
  return (
    <>
      <HeaderTitle title={i18next.t("setting")} />
      <div className="setting-container">
        <div className="menu-items">
          <Link to="/member/information" className="item ">
            <div className="left">{i18next.t("member_info")}</div>
            <div className="right">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/change-password" className="item ">
            <div className="left">{i18next.t("password")}</div>
            <div className="right">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/change-wallet-password" className="item ">
            <div className="left">{i18next.t("wallet_pass")}</div>
            <div className="right">
              {!user?.wallet_password && (
                <span>{i18next.t("not_setting")}</span>
              )}
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          {/* <Link to="/member/setting-general" className="item ">
            <div className="left">{i18next.t("setting1")}</div>
            <div className="right">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link> */}
          {/* <Link to="/member/contact" className="item ">
            <div className="left">Ý kiến phản hồi</div>
            <div className="right">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/about" className="item ">
            <div className="left">Về chúng tôi </div>
            <div className="right">
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link> */}
        </div>
        <div className="member-footer">
          <button
            type="button"
            className="btn btn-logout"
            onClick={() => onLogout()}
          >
            {i18next.t("logout")}
          </button>
        </div>
      </div>
    </>
  );
});

export default SettingPage;
