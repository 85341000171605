import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import i18next from "i18next";
import ListVideo from "./ListVideo";

const MoviePage = observer(() => {
  const [active_tab, setActiveTab] = useState("new");
  const tabs = {
    new: i18next.t("new"),
    hot: i18next.t("hot"),
    recommend: i18next.t("recommend"),
    video1: i18next.t("video1"),
    video2: i18next.t("video2"),
  };

  return (
    <>
      <HeaderTitle title={i18next.t("movie")} isBack={false} />
      <div className="main-content">
        <div className="page-content">
          <div className="video-container">
            <div className="van-tabs">
              <div className="van-tabs__nav">
                {Object.keys(tabs).map((key) => (
                  <div
                    key={key}
                    className={classNames("van-tab", {
                      active: active_tab === key,
                    })}
                    onClick={() => setActiveTab(key)}
                  >
                    <p>{tabs[key]}</p>
                  </div>
                ))}
              </div>
            </div>
            <ListVideo type={active_tab} />
          </div>
        </div>
      </div>
    </>
  );
});

export default MoviePage;
